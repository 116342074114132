import React from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { Link } from "gatsby"

const SelfCheckButton = ({primary=false, pl=null, concepts=[], textbook=null}) => {
  return (
    <Button
      as={Link}
      to={"/selfCheck?"+(pl?"pl="+pl+"&":"")+"concepts="+concepts.join(',')+"&"+(textbook?"textbook="+textbook+"&":"")}
      primary={primary}
      icon
      labelPosition='left'
    >
      <Icon name='tasks' />
      Check Yourself
    </Button>
  )
}

export default SelfCheckButton

